import React, { useEffect, useState } from 'react';
import styles from './RotateDevicePrompt.module.css';

const RotateDevicePrompt = () => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);

    const handleResize = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (!isPortrait) {
        return null; // Do not render anything if not in portrait mode
    }

    return (
        <div className={styles.overlay}>
            <div className={styles.messageContainer}>
                <p className={styles.message}>لطفاً موبایل خود را بچرخانید.</p>
            </div>
        </div>
    );
};

export default RotateDevicePrompt;
