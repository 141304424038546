import {useNavigate} from "react-router-dom";
import auth_image from "../assets/images/auth_image.svg";
import {useMobile} from "../MobileContext";
import desktop_styles from "../styles/home/HomeDesktop.module.css";
import mobile_styles from "../styles/home/HomeMobile.module.css";
import {useEffect, useState} from "react";
import {fetchCurrentUser} from "../APIs/auth";
import {user_role_map} from "./auth/Consts";
import React from "react";
import LoadingComponent from "../components/Loading/Loading";

const Home = () => {
    const isMobile = useMobile();
    const styles = isMobile ? mobile_styles : desktop_styles;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true); // Loading state

    const checkUserRole = async () => {
        try {
            const user = await fetchCurrentUser();
            const userRole = user_role_map[user.data.user.groups[0]];
            if (userRole === "Client") {
                navigate("/user");
            } else if (userRole === "FinancialManager") {
                navigate("/financial-manager");
            } else if (userRole === "Manager") {
                navigate("/manager");
            } else if (["Auditor", "CEO", "Admin"].includes(userRole)) {
                navigate("/auditor");
            } else {
                // User is authenticated but role is unrecognized, stay on home page
                setLoading(false);
            }
        } catch (error) {
            // User is not authenticated, stay on home page
            setLoading(false);
        }
    };

    useEffect(() => {
        checkUserRole();
    }, []);

    if (loading) {
        return (
            <LoadingComponent isMobile={isMobile}/>
        )
    }

    return (
        <div className={styles.HomePageContainer}>
            {!isMobile && (
                <img
                    className={styles.HomePagePhoto}
                    src={auth_image}
                    alt="Background"
                />
            )}
            <div className={styles.FooterContainer}>
                <h1 className={styles.Header}>ایران‌تدارکات</h1>
                <div className={styles.ButtonsContainer}>
                    <button
                        className={styles.LoginButton}
                        onClick={() => navigate(`/login`)}
                    >
                        ورود به سامانه
                    </button>
                    <button
                        className={styles.SignupButton}
                        onClick={() => navigate(`/signup`)}
                    >
                        ثبت‌نام در سامانه
                    </button>
                </div>
            </div>

        </div>
    );
};

export default Home;